export const func = {

  calculaIdade: (req) => {
    let dataNascimento = req.split("/");
    let dataAtual = new Date()
      .toLocaleString("pt-BR")
      .split(" ")[0]
      .split("/");
    var diaAtual = parseInt(dataAtual[0]);
    var mesAtual = parseInt(dataAtual[1]);
    var anoAtual = parseInt(dataAtual[2]);
    var diaNascimento = parseInt(dataNascimento[0]);
    var mesNascimento = parseInt(dataNascimento[1]);
    var anoNascimento = parseInt(dataNascimento[2]);
    var result = diaNascimento > diaAtual && mesNascimento >= mesAtual
      ? anoAtual - anoNascimento - 1
      : anoAtual - anoNascimento;
    return result;
  },
  convertDate: (date) => {
    if (date.includes('T')) {
      return date.toString().split('T')[0].split('-').reverse().join('/');
    }
    return new Date(date + ' 00:00:00').toLocaleString('pt-BR').split(',')[0];
  },

  invertDate: (date) => {
    if(date.includes(" ")){
      date = date.split(" ")[0];
    }
    return date.split('/').reverse().join('-');
  },

  convertMoney: (money) => {
    if (!money) {
      let a = 0;
      return a.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });
    }
    if (money.toString().includes('.')) {
      return parseFloat(money).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });
    }
    else {
      return parseFloat(money).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });
    }
  },
  convertMoney2: (money, casas) => {
    const numberCasas = casas || 2;
    if (!money) {
      let a = 0;
      return a.toLocaleString('pt-br', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
    }
    return parseFloat(money).toLocaleString('pt-br',
      { minimumFractionDigits: numberCasas, maximumFractionDigits: numberCasas });
  },
  convertDateTime: (date) => {
    if (date.toString().includes("T")) {
      return new Date(date.split("T")[0] + ' 00:00:00').toLocaleString('pt-BR').split(',')[0]
    }
    else if (date.toString().includes(" ") == true) {
      if (date.split(' ')[0].split('-')[2].length == 4) {
        return new Date(date.split(' ')[0].split('-').reverse().join('-') + ' 00:00:00').toLocaleString('pt-BR').split(',')[0]
      }
      else {

        return new Date(date + ' 00:00:00').toLocaleString('pt-BR').split(',')[0];
      }
    }
    else if (date.toString().includes("/") == true) {
      if (date.split('/')[2].length == 4) {
        return new Date(date.split('/').reverse().join('-') + ' 00:00:00').toLocaleString('pt-BR').split(',')[0];
      }
    }

    else if (date.toString().includes("-") && date.toString().split('-')[0].length == 4) {
      return new Date(date + ' 00:00:00').toLocaleString('pt-BR').split(',')[0];
    }
    else {

      return new Date(date.split('-').reverse().join('-') + ' 00:00:00').toLocaleString('pt-BR').split(' ')[0];
    }
  },
  getCurrentDate: () => {
    return new Date(new Date().getTime() - (new Date().getTimezoneOffset() * 60000)).toISOString().slice(0, 19).replace('T', ' ')
  },
  // alterJSONArchive: () => {
  //   const fs = require('fs');
  //   const env = require("@/config/env.json");
  //   const data = env;
  //   const json = data;
  //   const modifiedData = JSON.stringify(json);
  //   fs.writeFileSync('@/config/env.json', modifiedData);
  //   console.log('sucess')

  // },
  isValidDate(date) {
    date = this.invertDate(date);
    return !isNaN(new Date(date).getTime());
  },
  convertTime: (date) => {
    if (date) {
      var dataUTCConvert1 = new Date(date);
      var hour = dataUTCConvert1.getHours();
      var min = dataUTCConvert1.getMinutes();
      if (hour < 10) {
        hour = "0" + hour;
      }
      if (min < 10) {
        min = "0" + min;
      }

      return hour + ":" + min;
    }
    return '00:00';
  },
  removeAcento: (data) => {
    data = data.toLowerCase();
    data = data.replace(new RegExp('[ÁÀÂÃ]', 'gi'), 'a');
    data = data.replace(new RegExp('[ÉÈÊ]', 'gi'), 'e');
    data = data.replace(new RegExp('[ÍÌÎ]', 'gi'), 'i');
    data = data.replace(new RegExp('[ÓÒÔÕ]', 'gi'), 'o');
    data = data.replace(new RegExp('[ÚÙÛ]', 'gi'), 'u');
    data = data.replace(new RegExp('[Ç]', 'gi'), 'c');
    return data.toUpperCase();
  },
  validateEmail: (email) => {
    var re = /\S+@\S+\.\S+/;
    return re.test(email);
  },
  getFirstDay: () => {
    const date = new Date();
    const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    return firstDay.toLocaleDateString();
  },
  getLastDay: () => {
    const date = new Date();
    const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    return lastDay.toLocaleDateString();
  },
  sortItems: (items, order, ascdesc) => {
    if (ascdesc === "DESC") {
      return items.sort((b, a) => {
        const itemA = a[order]?.toString() || "";
        const itemB = b[order]?.toString() || "";
        return itemA.localeCompare(itemB);
      });
    } else {
      return items.sort((a, b) => {
        const itemA = a[order]?.toString() || "";
        const itemB = b[order]?.toString() || "";
        return itemA.localeCompare(itemB);
      });
    }
  }

}